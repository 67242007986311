<template>
	<div class="notice_popup human int">
		<div class="close">
			<img src="/images/mem/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit" style="margin-left: 9px;">NCS 직무자가평가 하기</div>
		<div class="member" style="line-height: 35px;">‘NCS 직무자가평가’를 진행해 주세요!<br/>기술등급 평가에 도움이 됩니다!</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="$emit('close', true)">평가하러가기</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {}
	},
    methods: {
	}
}
</script>